/*vsechny soubory - obsahem je absolutni zaklad vyuzity jak na titulce, tak podstrankach, nezalinkovano do FTB*/

@import "W:/ASPINCLUDE/vismoWeb5/html/ecco2/ecco";
@import "vars";
@import "ftb";

@include link {
	text-decoration-skip: ink;
}

h5,
h4,
h3,
p {
	margin: 20px 0 5px 0;
	padding: 0;
}

p {
	margin: 8px 0;
}

ul,
ol {
	margin-top: 10px;
	margin-bottom: 10px;
}


li {
	margin-top: 2px;
	margin-bottom: 2px;
}


@include link-all('a.patalogo') {
	color: #DA0500 !important;
	font-weight: bold;
	border-radius: 2px;
	text-decoration: none !important;
	background-color: #ffffff !important;
	padding: 1px .26em;
}

.patalogo span {
	color: black!important;
	font-weight: normal;

	&.vis {
		font-weight: bold;
	}
}

hr {
	display: block;
	height: 1px;
	line-height: 1px;
	overflow: hidden;
	margin: 28px 0 4px 0;
	padding: 0;
	border: 0;
}

img {
	border: 0;
	font-weight: normal;
	max-width: 100%;
	height: auto;
}

.vystraha {
	font-weight: bold;
	font-style: normal;
}

.sf {
	float: none !important;
	display: block;
	clear: both;
	font-size: 0;
	line-height: 0;
	height: 0;
	border: 0;
	padding: 0;
	margin: 0;
	overflow: hidden;
	min-height: 0;
	max-height: 0;
}

#celek .sf *,
a#hlobsah,
.sf,
#anketa .hlas div {
	color: transparent!important;
}

form {
	@extend %reset;
}

.format,
.btn {
	@extend %border-box;
}

.btn {
	@extend %reset-input;
	cursor: pointer;
}

.cist {
	position: absolute;
	left: -10000px;
	top: 0;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

.skryt {
	display: none;
}

.fulltex {
	background-color: #333333;
	color: white;
	font-weight: bold;
	padding: 0 4px;
}

ul#volsocialhub {
	@extend %sf;
	display: block;
	margin: 0 0 7px 0;
	padding: 17px 0 0 0;

	li {
		margin: 0 20px -7px 0;
		padding: 0;
		float: left;
		white-space: nowrap;
		display: block;
		list-style-type: none;

		a {
			display: block;
			padding: 8px 0 8px 30px;
		}
	}
}

.facebook {
	background: transparent url(#{$cestaIkonySVG}share_fb.svg) no-repeat left center;
}

.twitter {
	background: transparent url(#{$cestaIkonySVG}share_tw.svg) no-repeat left center;
}

li.zaslat {
	background: transparent url(#{$cestaIkonySVG}share_mail.svg) no-repeat left center;
}

.google {
	background: transparent url(#{$cestaIkonySVG}share_g.svg) no-repeat left center;
}

@import "kalendare";
@import "voliviewer_import";

#vismo-cookie-wrapper {
	text-align: center;
	position: fixed;
	width: 100%;
	height: 0;
	overflow: visible;
	z-index: 60;
	left: 0;
	right: 0;
	font-size: 14px;
}

#vismo-cookie-panel {
	@include transition(bottom 1s ease-out);
	position: fixed;
	width: 100%;
	z-index: 1;
	bottom: 0;
	left: 0;
	right: 0;
	background: #343434;
	color: #ffffff;

	p {
		margin: 0;
		padding: 5px 15px;
		line-height: 1;
	}

	.vismo-cookie-text {
		display: inline-block;
		vertical-align: middle;
		line-height: 1.2;
		padding: 0 14px;

		a {
			display: inline-block;
			text-decoration: underline;
			color: #ffffff;
		}
	}

	.vismo-cookie-button {
		display: inline-block;
		vertical-align: middle;
	}

	#vismo-cookie-agree-button {
		@include transition(background-color .3s ease-out);
		display: inline-block;
		background-color: #E4E4E4;
		text-decoration: none;
		padding: 8px 15px;
		margin: 0;
		border-radius: 3px;
		color: #343434;
	}

	#vismo-cookie-agree-button:hover {
		background-color: #ffffff;
	}

	.vismo-cookie-clear {
		display: block;
		height: 0;
		line-height: 0;
		font-size: .1px;
		clear: both;
		width: 100%;
	}
}


.inline li {
	display: inline;
	list-style-type: none;

	&::before {
		position: relative;
		content: "\|";
		margin: 0 10px 0 11px;
	}

	&:first-child::before,
	&.skip::before,
	&.skip + li:not(.skip)::before {
		content: normal;
	}
}


@include bp("< 800px") {
	#vismo-cookie-panel {
		p {
			padding-top: 9px;
			padding-bottom: 9px;
		}

		.vismo-cookie-button {
			margin-top: 10px;
			display: block;
		}
	}
}

.aboEonBanner {
	text-align: center;
	padding: 20px 0 30px 0;

	a {
		display: inline-block;
	}
}

#abo table td {
	text-align: left;

	img {
		max-width: none;
	}
}

script {
	display: none !important;
}

#kalakci {
  .floatingBubbleElm p {
    font-size: rem(16px);
  }

  td,
  th {
    font-size: rem(15px);
  }
}

.vzzalozky,
#rejstrik ul.rejstrikpismena {
	font-size: rem(17px);
}


#fzapis .fnote,
.popis.dpopis,
.zobrazeno.kontext2,
#prepinac,
.vyhlodkaz {
	font-size: rem(15px);
}


.akce-podle-data {
	margin: 0 0 30px 0;

	.inline {
		@extend %reset-list;
		display: inline;
	}
}

/*************************************************
*	POČASÍ - BLUEBOARD (STŘEDNÍ VELIKOST, DVA DNY)
*************************************************/

// #pocasi {
// 	@extend %sf;

// 	div {
// 		background-color: transparent !important;

// 		div,
// 		p {
// 			width: 100% !important;
// 		}

// 		div {
// 			padding: 0;
// 			margin-bottom: 0;

// 			div {
// 				width: 50% !important;
// 			}
// 		}
// 	}

// 	p {
// 		font-size: rem(16px) !important;
// 		@extend %reset;
// 		clear: both;
// 	}

// 	a {
// 		clear: both;
// 		padding-top: 5px;
// 		width: auto !important;
// 		height: auto !important;
// 		background-color: transparent !important;
// 		text-align: left !important;
// 		padding-left: 35px !important;
// 	}
// }


[id*="volrecaptcha"] {
	text-align: center;
	padding: 15px 0;
	clear: both;
}

[id*="volrecaptcha"] > div {
	margin: 0 auto;
}

.volrecaptcha {
	clear: both;
	padding: 15px 0;

	&::after {
		content: "";
		height: 0;
		display: table;
		width: 100%;
		clear: both;
	}
}

.volrecaptcha [id*="volrecaptcha"] {
	padding: 0;
}



.vol-sdileni {
	@extend %reset-list;
	margin-top: 35px;

	li {
		display: inline-block;
		margin: 0;

		* {
			vertical-align: middle;
		}

		a {
			display: inline-block;
		}

		span {
			padding-left: 5px;
			padding-right: 1em;
			vertical-align: baseline;
		}

		@each $n, $c in ( facebook, #3B5998 ), ( twitter, #1DA1F2 ), ( google, #DB4437 ), ( mail, #444444 ) {
			&.sdilet-#{$n} img {
				background-color: $c;
				border-radius: 50%;
			}
		}

		img {
			color: #ffffff;
		}

		@include bp("< 400px") {
			img {
				width: 32px;
				height: 32px;
			}
		}
	}
}


table.calendar {
	background-color: #ffffff;
}



/* 11. 4. 2017 - kvůli novému Google Custom Search */

// Následující zakomentováno, protože n_styly_5 používají autoprefixer
// #hl::-webkit-input-placeholder { /* Chrome/Opera/Safari */
//   color: transparent;
// }

// #hl::-moz-placeholder { /* Firefox 19+ */
//   color: transparent;
// }

// #hl:-ms-input-placeholder { /* IE 10+ */
//   color: transparent;
// }

// #hl:-moz-placeholder { /* Firefox 18- */
//   color: transparent;
// }

#hl::placeholder {
  color: transparent;
}

/* JakubKo 20170525 */
@import "pocasi";


/** JakubKo 20180508 - GDPR */
.titulformular .bezny.gdprsouhlas {
	margin-top: .85em;
	margin-bottom: 1em;
	padding-bottom: 0;
}

#souhlasform,
#odvolanisouhlasuform {
	&::after {
		content: "";
		height: 0;
		display: table;
		clear: both;
	}
}

.feditace p.gdprsouhlas {
	margin-bottom: 1.65em;
}

#stranka [action*="/aa/"] .fbtn {
	text-align: right; //Obejití absence vismo_special.css
}
/** JakubKo 20180508 - /GDPR */





/************************
PŘEPISY
************************/

[class*="individualy-"],
.vzor-id-2017,
.vzor-f-2018 {
	@include edge-only {
		#kalakci .calcells td::before{
			top: 1em;

			@include bp("> 450px"){
				top: 1.25em;
			}
		}
	}

	h5 {
		margin-bottom: .4em;
	}
}

.vzor-f-2018 {
	h5,
	h6 {
		font-family: "MagraWeb";
	}
}

.vzor-e-2016 {
	h5,
	h6 {
		margin-bottom: .4em;
		font-family: "TekoWeb";
	}

	h5 {
		font-size: 1.165em;
	}

	.dok .dok-nazev {
		display: block;
	}
}

// zarovnani "registrovat se" na strance přihlášení
#fprihl.fkont.nizky .fvpravo p{
	text-align: right;
}