/*pro editor a vsechny stranky s editorem, nemelo by byt zalinkovane k prihlasovacim formularum a jinde, kde nen� editor. pokud neni na strance vyplneny .editor, nemelo by byt v needitacnim rezimu pripojeno*/

//velikosti textu
.small {
   font-size: to-length((12 / 16), rem);
}

.big {
   font-size: to-length((18 / 16), rem);
}

.extrabig {
   font-size: to-length((25 / 16), rem);
}

.editor {
   #stranka & {
      margin-bottom: 25px;
   }

   table {
      border-spacing: 0;
      border-collapse: collapse;
      max-width: 100%;
   }

   td,
   th {
      text-align: left;
      padding: 8px;
      border: 1px #868686 solid;
      vertical-align: top;

      &[align="center"] {
         text-align: center;
      }

      &[align="right"] {
         text-align: right;
      }
   }

   table[border="0"] {
      td,
      th {
         border: 0;
      }
   }

   th {
      background-color: #eeeeee;
   }

   p {
      padding: 0;
      margin: 8px 0 0 0;
   }

   font[size="5"],
   font[size="6"] {
      line-height: normal;
   }

   img {
      position: relative;
      float: left;
      margin: 4px 15px 4px 0;

      &.vpravo {
         float: right;
         margin: 4px 0 4px 15px;
      }
   }

   p.nastred img {
      float: none;
   }

   .nastred {
      width: auto;
      text-align: center;
   }

   hr {
      margin: 8px 0;
      width: 100%;
      clear: both;
      display: block;
   }

   ul,
   ol {
      clear: left;
      margin: 10px 0 10px 25px;
      padding: 0;
   }

   ol {
      margin-left: 30px;
   }

   li ul {
      margin: 5px 0;
   }

   li ol {
      margin: 5px 0 5px 10px;
   }

   li {
      padding: 0;
      margin: 2px 0 2px 15px;
   }

   blockquote {
      padding: 5px 0;
      margin: 0 0 0 15px;
   }

   ol li {
      list-style-type: decimal;
   }

   li ol li {
      list-style-type: lower-alpha;
   }

   li li ol li {
      list-style-type: lower-roman;
   }

   ul li {
      list-style-type: square;
   }

   ul.mbannery {
      margin: 0;
      padding: 0;
      list-style-type: none;
      text-align: center;

      li {
         padding: 0;
      }

      li,
      li a {
         display: inline-block;
      }

      img {
         display: inline;
      }

      li,
      img {
         float: none !important;
         vertical-align: middle;
         margin: 7px 3px;
      }
   }
}

body.editor {
   padding: 10px;

   p {
      border: 1px blue dashed;
   }

   img {
      position: static;
   }

   .sf {
      border-bottom: 1px green dashed;
      margin: 3px 0 0 0 !important;
      padding: 0;
      overflow: hidden;
      float: none !important;
      display: block;
      clear: both;
      font-size: 0;
      line-height: 0;
      height: 0;
      max-height: 0;
   }
}

.sfleft {
   clear: left !important;
}

.justify {
   text-align: justify;
}

.malamezera {
   line-height: 5px;
}

.velkamezera {
   line-height: 15px;
}

.yellowbg {
   background-color: yellow !important;
   color: black;
}

.redbg {
   background-color: #f00000 !important;
   color: white;
}

.blue {
   color: blue;
}

.green {
   color: green;
}

.red {
   color: red;
}

.orange {
   color: #ffa500;
}

.yellow {
   color: yellow;
}

.darkgray {
   color: #a9a9a9;
}

.white {
   color: white;
   background-color: silver;
}

.black {
   color: black;
}

.zvyrazneni2 {
   padding: 0 3px;
}

.ftb-ytb-video{
    &__wrapper{
        position: relative;
        height: 0;
        padding-bottom: 56.25%;
        max-width: 100%;
    }
    iframe{
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}